import request from '@/utils/request'
import apiConfig from '@/api/apiconfig'
/**
 * 登录
 */
export function reqGuangfaLogin(data) {
  return request({
    url: `${apiConfig.ZY}/guangfaBank/login`,
    method: 'post',
    data
  })
}
/**
 * 退出
 */
export function reqGuangfaLout(data) {
  return request({
    url: `${apiConfig.ZY}/guangfaBank/logout`,
    method: 'post',
    data
  })
}
/**
 * 发送验证码
 */
export function reqGuangfaSendCaptcha(data) {
  return request({
    url: `${apiConfig.ZY}/guangfaBank/sendCaptcha`,
    method: 'post',
    data
  })
}
/**
 * 广发卡券下发
 */
export function reqGuangfaSendCard(data) {
  return request({
    url: `${apiConfig.ZY}/guangfaBank/buyCard`,
    method: 'post',
    data
  })
}
/**
 * 广发校验登录状态
 */
export function reqGuangfaCheckLogin(data) {
  return request({
    url: `${apiConfig.ZY}/guangfaBank/checkLoginStatus`,
    method: 'post',
    data
  })
}
